import { useEffect, useState } from 'react';
import { Box, Typography, Button, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { InputText } from './elements/inputText';
import SelectControl from './elements/SelectControl';
import { emailPattern } from './../utils/index';
import InfoTooltip from './elements/infoTooltip';
import { registerAuth } from './../service/authService';
import { useResponseError } from './../hooks/useResponseError';
import { useTheme } from '@mui/material/styles';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { STORAGE_USER_CONFIRM_TOKEN } from '../constants/storage';
import { useCookies } from 'react-cookie';
import ReactGA from "react-ga4";

type RegisterFormFields = {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	city?: string;
	state?: string;
	zip?: string;
	roles: 'VENDOR' | 'BUYER' | 'BOTH';
	termsOfUse: boolean;
};

const AccountRegister = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const { responseError, setResponseError } = useResponseError(50000);
	const [isSubmitDissable, setIsSubmitDissable] = useState(true);

	const {
		control,
		register,
		handleSubmit,
		reset,
		watch,
		getValues,
		formState: { errors, dirtyFields },
	} = useForm<RegisterFormFields>({
		defaultValues: {
			email: '',
			firstName: '',
			lastName: '',
			city: '',
			state: '',
			zip: '',
			roles: 'BUYER',
			termsOfUse: false,
		},
		mode: "onChange",
	});

	const dicRoles = {
		'VENDOR': 'VENDOR',
		'BUYER': 'BUYER',
		'BOTH': 'VENDOR',
	}
	const [cookie, setCookie, removeCookie] = useCookies(["userType"]);

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if (name === 'roles') {
				//@ts-ignore
				setCookie("userType", dicRoles[value.roles], { path: '/' });
			}

			if (name === 'termsOfUse') {
				setIsSubmitDissable(!value.termsOfUse)
			}
		}
		)
		return () => subscription.unsubscribe()
	}, [watch]);

	const onSubmit = async (data: any) => {
		const dicRoles = {
			'VENDOR': ['VENDOR'],
			'BUYER': ['BUYER'],
			'BOTH': ['VENDOR', 'BUYER']
		}
		//@ts-ignore
		data.roles = dicRoles[data.roles];
		data.passwordConfirm = data.password;
		delete data.termsOfUse

		try {
			//@ts-ignore
			const res = await registerAuth({
				data
			});
			ReactGA.event('sign_up', {
				email: data?.email,
				firstName: data?.firstName,
				lastName: data?.lastName,
				roles: data?.roles,
			});
			localStorage.setItem(STORAGE_USER_CONFIRM_TOKEN, res.accessToken);
			navigate(`/account/confirm`, {
				replace: true
			})
		} catch (error: any) {
			setResponseError(error.message)
		} finally {
			// setIsLoading(false);
		}

	}

	return <>
		<Box sx={
			[{
				backgroundColor: 'white',
				py: '30px',
				px: '56px',
				maxWidth: '500px',
				boxSizing: 'border-box',
				margin: '0 auto',
				borderRadius: '10px',
			},
			(theme) => ({
				[theme.breakpoints.down('sm')]: {
					px: '15px',
				}
			}),
			]}>
			<Typography variant="h4" style={{
				fontWeight: '700',
				fontSize: '21px',
				color: '#000',
				textAlign: 'center',
				marginBottom: '18px',
			}}>
				Registration
			</Typography>
			<Box
				component="form"
				autoComplete="off"
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					'& > :not(style)': { my: 0.5, width: '100%' },
					'display': 'flex',
					'flexDirection': 'column',
				}}
			>
				<Box sx={{
					'& > :first-of-type': { width: '100%' },
					'display': 'flex',
					alignItems: 'center',
					'position': 'relative',
					width: '100%'
				}}>
					<Controller
						control={control}
						name='roles'
						render={({ field: { onChange, onBlur, value } }) => (
							<SelectControl
								label='Roles'
								items={
									[
										{ value: 'BUYER', label: 'Buyer' },
										{ value: 'VENDOR', label: 'Vendor' },
										{ value: 'BOTH', label: 'Vendor and Buyer' },
									]
								}
								onBlur={onBlur}
								onChange={onChange}
								value={value}
							/>
						)}
					/>
					<Box sx={[
						{
							'display': 'flex',
							'position': 'absolute',
							right: '-45px',
						},
						(theme) => ({
							[theme.breakpoints.down('sm')]: {
								display: 'none'
							}
						}),
					]}>
						<InfoTooltip text={'You can register as a vendor, buyer or both.'} />
					</Box>
				</Box>
				<InputText
					label="First Name"
					variant="filled"
					inputProps={{ ...register("firstName", { required: true, maxLength: 30 }) }}
					error={!!errors['firstName']}
					helperText={errors['firstName'] ? 'This is required, maximum length 30 characters' : ''}
				/>
				<InputText
					label="Last Name"
					variant="filled"
					inputProps={{ ...register("lastName", { required: true, maxLength: 30 }) }}
					error={!!errors['lastName']}
					helperText={errors['lastName'] ? 'This is required, maximum length 30 characters' : ''}
				/>
				<InputText
					label="City"
					variant="filled"
					inputProps={{ ...register("city", { required: true }) }}
					error={!!errors['city']}
					helperText={errors['city'] ? 'This is required' : ''}
				/>
				<Box sx={{
					'display': 'flex',
					alignItems: 'center',
					width: '100%'
				}}>
					<InputText
						sx={{
							maxWidth: '60%',
							width: '100%',
							mr: 1
						}}
						label="State"
						variant="filled"
						inputProps={{ ...register("state", { required: true }) }}
						error={!!errors['state']}
						helperText={errors['state'] ? 'This is required' : ''}
					/>
					<InputText
						sx={{
							maxWidth: '40%',
							width: '100%',
						}}
						label="Zip"
						variant="filled"
						inputProps={{ ...register("zip", { required: true }) }}
						error={!!errors['zip']}
						helperText={errors['zip'] ? 'This is required' : ''}
					/>
				</Box>
				<InputText
					label="Email address"
					variant="filled"
					inputProps={{ ...register("email", { required: true, pattern: emailPattern.value }) }}
					error={!!errors['email']}
					helperText={errors['email'] ? emailPattern.message : ''}
				/>
				<InputText
					label="Password"
					variant="filled"
					type="password"
					inputProps={{
						...register("password", {
							required: true, validate: {
								isValid: (value) => {
									return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
								}
							}
						})
					}}
					error={!!errors['password']}
					helperText={errors['password'] ? errors['password'].message : ''}
				/>
				<Controller
					name="termsOfUse"
					control={control}
					rules={{ required: true }}
					render={({ field }) =>
						<FormControlLabel
							control={
								<Checkbox {...field} />
							}
							label={
								<>
									I agree to SwapIt Fast's <Link to={"https://swapitfast.com/terms-of-use"} target="_blank">Terms of Use</Link>
								</>
							}
						/>
					}
				/>
				<Box sx={{
					textAlign: 'left',
					color: theme.palette.error.main,
				}}>{responseError}</Box>
				<Box sx={{ backgroundColor: 'white', borderRadius: '10px', pt: '5px' }}>
					<Button
						type="submit"
						sx={{
							py: '8px',
							borderRadius: '10px',
							width: '100%'
						}}
						variant="contained"
						disabled={isSubmitDissable}
					>
						Create account
					</Button>
				</Box>
			</Box>
		</Box >
	</>;
};

export default AccountRegister;