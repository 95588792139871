import { useState, useEffect } from 'react';
import {
  Box,
  Switch,
  Typography,
  FormGroup,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button,
  Radio,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { TPricinPlan } from '../types/types';
import { useNavigate, useSearchParams } from "react-router-dom";
import { createCheckoutSession, getSubscriptions, getCustomerPortal, getPricingPlans } from './../service/subscriptionService';
import { useResponseError } from '../hooks/useResponseError';
import { useAuth } from '../hooks/useAuth';
import { removeUser, switchRoleMode, toggleUserRole } from '../service/userService';
import { useTheme } from '@mui/material/styles';
import { getUserData } from '../service/userService';
import { UPDATE_PRICE_OPTIONS } from '../constants/const';
import { useLayout } from '../pages/Layout';
import { Loader } from './elements/Loader';
import { CloseModal } from './modal/CloseModal';
import ReactGA from "react-ga4";

const VendorGeneralSettings = ({ clearSignature }: { clearSignature: Function }) => {
  const { toggleShowSwitch } = useLayout();
  const { logout, setUser, user, isBuyer, isVendor, userType } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const subscriptionParam = searchParams.get("subscription");
  const theme = useTheme();

  const [checked, setChecked] = useState(userType === 'VENDOR');
  const [toggleModalStatus, setToggleModalStatus] = useState('');
  const [open, setOpen] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openSub, setOpenSub] = useState(false);
  const [customerStripeInfo, setCustomerStripeInfo] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState('Inactive');
  const [pricingPlanOptions, setPricingPlanOptions] = useState<TPricinPlan[]>([]);
  const [pricingPlan, setPricingPlan] = useState('');
  const [subInterval, setSubInterval] = useState('');
  const [dateRenewal, setDateRenewal] = useState('');
  const [dateLabel, setDateLabel] = useState('Date of renewal');
  const { responseError, setResponseError } = useResponseError();

  useEffect(() => {
    const getUserObj = async () => {
      try {
        const res = await getUserData({});

        setUser(res);
      } catch (error: any) {
        console.error('error', error)
      }
    };

    getUserObj();

    return () => { };
  }, []);


  useEffect(() => {
    const getCustomer = async () => {
      if (user) {
        try {
          const res = await getSubscriptions({ status: ['active', 'trialing'] });

          if (res) {
            setCustomerStripeInfo(res.data[0]);
            const sub = res?.data ? res?.data[0] : null;

            if (sub) {
              setSubscriptionStatus(sub?.status);

              const dicInterval: {
                [key: string]: string
              } = {
                'month': 'Monthly',
                'year': 'Annual'
              }
              setSubInterval(dicInterval[sub.interval]);
              if (sub.cancelAt) {
                const cancelDate = new Date(sub.cancelAt);
                setDateLabel('Expires');
                setDateRenewal(`${cancelDate.getMonth() + 1}-${cancelDate.getDate()}-${String(cancelDate.getFullYear()).slice(2)}`)
              }

              if (!sub.cancelAt && sub.currentPeriodEnd) {
                const dateSub = new Date(sub.currentPeriodEnd);
                setDateLabel('Date of renewal');
                setDateRenewal(`${dateSub.getMonth() + 1}-${dateSub.getDate()}-${String(dateSub.getFullYear()).slice(2)}`)
              }
            }
          }
        } catch (error: any) {
          console.error('error', error)
        }
      }
    };

    getCustomer();

    return () => { };
  }, [subscriptionParam]);

  const handleClickToggleRole = async (isRemove: boolean) => {
    try {
      const res = await toggleUserRole({
        id: user.id,
        role: isRemove ? "VENDOR" : "BUYER"
      })

      if (isRemove) {
        ReactGA.event('removeRole',
          {
            value: 'Vendor',
            userID: user?.id,
            email: user?.email,
            firstName: user?.firstName,
            lastName: user?.lastName,
            phoneNumber: user?.phoneNumber,
            city: user?.city,
            state: user?.state,
            zip: user?.zip,
            roles: user?.roles,
          });
      } else {
        ReactGA.event('addRole',
          {
            value: 'Buyer',
            userID: user?.id,
            email: user?.email,
            firstName: user?.firstName,
            lastName: user?.lastName,
            phoneNumber: user?.phoneNumber,
            city: user?.city,
            state: user?.state,
            zip: user?.zip,
            roles: user?.roles,
          });
      }

      setOpen(true);
      const isVendor = res?.roles.includes('VENDOR');
      const isBuyer = res?.roles.includes('BUYER');
      toggleShowSwitch(isVendor && isBuyer ? true : false);
      setToggleModalStatus(isRemove ? 'Vendor role was successfully deleted' : 'Buyer Account was successfully added');
      setUser(res);
    } catch (error: any) {
      setResponseError(error.message);
      if (error.message === 'cannot remove vendor role with active subscription') {
        setOpen(true);
        setToggleModalStatus('You cannot delete a vendor role with an active subscription.');
      } else {
        setOpen(true);
        setToggleModalStatus(error.message);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (toggleModalStatus === 'Vendor role was successfully deleted') {
      navigate('/buyer/settings');
    }
  };

  const handleClickOpenRemove = () => {
    setOpenRemove(true);
  };

  const handleCloseRemove = () => {
    setOpenRemove(false);
  };

  const handleClickOpenSub = async () => {
    setOpenSub(true);
    try {
      const res = await getPricingPlans();
      localStorage.setItem(UPDATE_PRICE_OPTIONS, JSON.stringify(res));
      setPricingPlanOptions(res)
      const yearPlan = res.find((item: any) => item.interval === "year")
      if (yearPlan) {
        setPricingPlan(yearPlan.id);
      } else {
        setPricingPlan(res[0].id);
      }
    } catch (error: any) {
      setResponseError(error.message);
    }
  };

  const handleCloseSub = () => {
    setOpenSub(false);
  };

  const onSubmitRemove = async () => {
    try {
      const res = await removeUser({
        id: user.id,
      })

      ReactGA.event('removeUser',
        {
          userID: user?.id,
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phoneNumber: user?.phoneNumber,
          city: user?.city,
          state: user?.state,
          zip: user?.zip,
          roles: user?.roles,
        });

      setOpenRemove(false);
      logout();
    } catch (error: any) {
      setResponseError(error.message);
    }
  }

  const handleCreateCheckout = async () => {
    // const win = window.open('https://billing.stripe.com/p/session', '_blank');
    try {
      const res = await createCheckoutSession({
        priceId: pricingPlan
      })
      //@ts-ignore
      const resUrl = res.sessionUrl;

      if (resUrl) {
        ReactGA.event('createSubscribeCheckout',
          {
            priceId: pricingPlan,
            userID: user?.id,
            email: user?.email,
            firstName: user?.firstName,
            lastName: user?.lastName,
            phoneNumber: user?.phoneNumber,
            city: user?.city,
            state: user?.state,
            zip: user?.zip,
            roles: user?.roles,
          });

        //@ts-ignore
        // const win = window.open(resUrl, '_blank');
        // if (win) {
        //   win.location.href = resUrl;
        //   win?.focus();
        // }
        window.open(resUrl, '_self');
      } else {
        throw new Error("something wrong");
      }

    } catch (error: any) {
      setResponseError(error.message);
    }
  }

  const handleGetPortalCustomer = async () => {
    // const win = window.open('https://billing.stripe.com/p/session', '_blank');
    if (customerStripeInfo) {
      try {
        const res = await getCustomerPortal()
        //@ts-ignore
        const resUrl = res.sessionUrl;

        if (resUrl) {
          ReactGA.event({
            category: 'Vendor',
            action: 'CustomerPortal',
            label: 'Customer Portal',
          });
          //@ts-ignore
          // const win = window.open(resUrl, '_blank');
          // if (win) {
          //   win.location.href = resUrl;
          //   win?.focus();
          // }
          window.open(resUrl, '_self');
        } else {
          throw new Error("something wrong");
        }

      } catch (error: any) {
        setResponseError(error.message);
      }
    }

    return
  }

  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

    const res = await switchRoleMode(event.target.checked ? 'VENDOR' : 'BUYER', user?.id);
    clearSignature(userType);
    setUser(res);

    if (event.target.checked) {
      navigate("/vendor/settings");
    } else {
      navigate("/buyer/settings");
    }
  };

  const handleChangePricingPlan = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPricingPlan((event.target as HTMLInputElement).value);
  };

  const renderPricingPlanOptions = () => {
    return (
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={pricingPlan}
        onChange={handleChangePricingPlan}
      >
        {pricingPlanOptions.map((item: TPricinPlan) => {
          return <FormControlLabel
            key={item.id}
            value={item.id}
            control={<Radio />}
            label={`$${(Number(item.amount) / 100).toFixed(2)} per ${item.interval}`}
          />
        })}
      </RadioGroup>)
  }

  const getAccountStatus = () => {
    if (isVendor && isBuyer) {
      return 'Press the switch to enter buyer mode'
    }

    if (isVendor && !isBuyer) {
      return 'Vendor mode active'
    }

    if (!isVendor && isBuyer) {
      return 'Buyer mode active'
    }

    return 'Buyer mode active'
  }

  const getSubscriptionMsg = (param?: string | null) => {
    if (param) {
      return (<Typography variant="body1" sx={{
        fontWeight: '400',
        fontSize: '13px',
        color: param === 'cancel' ? '#EB5757' : '#008986',
        my: '10px'
      }}>
        {param === 'success' ? 'The subscription has been successfully created.' : ''}
        {param === 'cancel' ? 'Subscription cancelled.' : ''}
      </Typography>)
    }

    return ''
  }

  const renderAccountControls = () => {

    if (isBuyer) {
      return (
        <>
          <Button
            sx={{
              fontSize: '13px',
              px: '20px',
              marginBottom: '12px',
              color: '#EB5757',
              borderRadius: '10px',
              borderColor: '#EB5757',
              '&:hover': {
                borderColor: '#EB5757',
              },
              '&:active': {
                borderColor: '#EB5757',
              },
              '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
              },
            }}
            variant="outlined"
            color="error"
            onClick={() => handleClickToggleRole(true)}
          >
            Delete this role
          </Button>
          <Typography variant="body1" sx={{
            fontWeight: '400',
            fontSize: '13px',
            color: '#666666',
          }}>
            You will be able to restore it at any time.
          </Typography>
        </>
      )
    } else {

      return (
        <>
          <Button
            sx={{
              fontSize: '13px',
              px: '20px',
              marginBottom: '12px',
              borderRadius: '10px',
            }}
            variant="contained"
            onClick={() => handleClickToggleRole(false)}
          >
            Add Buyer account
          </Button>
          <Typography variant="body1" sx={{
            fontWeight: '400',
            fontSize: '13px',
            color: '#666666',
          }}>
            You can add another role and switch between them at any time.
          </Typography>
        </>
      )
    }
  }

  return <div>
    <Box sx={{
      'display': 'flex',
      'flexDirection': 'column',
    }}>
      <Typography variant="h4" sx={{
        fontWeight: '400',
        fontSize: '21px',
        color: '#000',
        marginBottom: '10px',
      }}>
        Active account
      </Typography>
      <Typography variant="body1" sx={{
        fontWeight: '400',
        fontSize: '13px',
        color: '#000',
        marginBottom: '18px',
      }}>
        {getAccountStatus()}
      </Typography>
      {isVendor && isBuyer &&
        <Box sx={{
          marginBottom: '30px',
        }}>
          <FormGroup>
            <FormControlLabel
              sx={{
                color: '#666666',
                marginLeft: '-8px',
              }}
              control={
                <Tooltip title="Switch mode">
                  <Switch
                    checked={checked}
                    onChange={handleSwitchChange}
                  />
                </Tooltip>
              }
              label={checked ? "Vendor mode active" : "Buyer mode active"}
            />
          </FormGroup>
        </Box>
      }
      <Box sx={{
        marginBottom: '60px'
      }}>
        {renderAccountControls()}
        <Dialog
          sx={[{
            '.MuiDialog-paper': {
              px: '55px',
              paddingBottom: '35px',
              pt: '20px',
            }
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              '.MuiDialog-paper': {
                px: '15px',
              }
            }
          }),
          ]}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseModal close={handleClose} />
          <DialogTitle sx={{
            fontWeight: '700',
            maxWidth: '300px',
            textAlign: 'center'
          }}>
            {toggleModalStatus}
          </DialogTitle>
          <DialogActions
            sx={{
              maxWidth: '325px',
              justifyContent: 'center',
            }}
          >
            <Button
              sx={{
                padding: '7px 20px',
                borderRadius: '10px',
                width: '100%',
              }}
              variant="contained"
              onClick={handleClose}
              autoFocus
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box sx={{
        'display': 'flex',
        'flexDirection': 'column',
        marginBottom: '60px'
      }}>
        <Typography variant="h4" sx={{
          fontWeight: '400',
          fontSize: '21px',
          color: '#000',
          marginBottom: '20px',
        }}>
          Subscription
        </Typography>
        {subscriptionStatus !== 'active' && subscriptionStatus !== 'trialing' ?
          <Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px'
            }}>
              <SvgIcon sx={{
                backgroundColor: '#fff',
                padding: '13px',
                borderRadius: '10px',
                mr: '10px',
                fontSize: 14,
                boxShadow: '0px 10px 10px 0px rgba(0, 137, 134, 0.1)',
              }}>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 13.5L13.5 2" stroke="#EB5757" strokeWidth="4" />
                  <path d="M13.5 13.5L2 2" stroke="#EB5757" strokeWidth="4" />
                </svg>
              </SvgIcon>
              <Typography variant="body1" sx={{
                fontWeight: '400',
                fontSize: '16px',
                color: '#000',
              }}>
                Inactive
              </Typography>
            </Box>
            <Button
              sx={{
                padding: '7px 20px',
                borderRadius: '10px',
                maxWidth: '255px'
              }}
              variant="contained"
              onClick={handleClickOpenSub}
              autoFocus
            >
              Activate subscription
            </Button>
            <Dialog
              sx={[{
                '.MuiDialog-paper': {
                  px: '55px',
                  paddingBottom: '35px',
                  pt: '20px',
                }
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  '.MuiDialog-paper': {
                    px: '15px',
                  }
                }
              }),
              ]}
              open={openSub}
              onClose={handleCloseSub}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <CloseModal close={handleCloseSub} />
              <DialogTitle sx={{
                fontWeight: '700',
                maxWidth: '300px',
                textAlign: 'center'
              }}>
                {`Choose a pricing plan`}
              </DialogTitle>
              <DialogContent sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <FormControl>
                  {pricingPlanOptions && pricingPlanOptions.length > 0 ? renderPricingPlanOptions() : <Loader />}
                </FormControl>
              </DialogContent>
              <DialogActions
                sx={{
                  maxWidth: '325px',
                  justifyContent: 'center',
                }}
              >
                <Button
                  sx={{
                    padding: '7px 20px',
                    borderRadius: '10px',
                    width: '100%',
                  }}
                  variant="contained"
                  onClick={handleCreateCheckout}
                  autoFocus
                >
                  Activate
                </Button>
              </DialogActions>
              <Box sx={{
                maxWidth: '325px',
                justifyContent: 'center',
                textAlign: 'center',
                color: theme.palette.error.main
              }}>
                {responseError}
              </Box>
            </Dialog>
          </Box> :
          <Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px'
            }}>
              <SvgIcon sx={{
                backgroundColor: '#fff',
                padding: '10px',
                borderRadius: '10px',
                mr: '10px',
                fontSize: 20,
                boxShadow: '0px 10px 10px 0px rgba(0, 137, 134, 0.1)',
              }}>
                <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 7L8.5 13.5L20 2" stroke="#FED701" strokeWidth="4" />
                </svg>
              </SvgIcon>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  color: '#000',
                  mr: '20px',
                  textTransform: 'capitalize'
                }}>
                {subInterval}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '400',
                  fontSize: '13px',
                  color: '#666666',
                  mr: '4px'
                }}>
                {dateLabel}:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '400',
                  fontSize: '13px',
                  color: '#000',
                }}>
                {dateRenewal}
              </Typography>
            </Box>
            <Button
              sx={{
                px: '20px',
                borderRadius: '10px',
                maxWidth: '246px',
                color: '#000'
              }}
              variant="outlined"
              onClick={handleGetPortalCustomer}
              autoFocus
            >
              Manage subscription
            </Button>
          </Box>}
        {getSubscriptionMsg(subscriptionParam)}
      </Box>
      <Box sx={{
        marginBottom: '60px'
      }}>
        <Typography variant="h5" sx={{
          fontWeight: '400',
          fontSize: '16px',
          color: '#000',
          marginBottom: '10px',
        }}>
          Logout?
        </Typography>
        <Button
          onClick={() => logout()}
          sx={{
            textDecoration: 'underline',
            padding: '0px',
            color: '#666666',
            borderRadius: '0px',
            borderColor: '#EB5757',
            fontSize: '13px',
            minWidth: 'auto',
            '&:hover': {
              borderColor: '#EB5757',
            },
            '&:active': {
              borderColor: '#EB5757',
            },
            '&:focus': {
              boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
          }}
          variant="text"
        >
          Logout
        </Button>
      </Box>
      <Box sx={{
        marginBottom: '60px',
      }}>
        <Typography variant="h5" sx={{
          fontWeight: '400',
          fontSize: '16px',
          color: '#000',
          marginBottom: '10px',
        }}>
          Delete account?
        </Typography>
        <Button
          onClick={handleClickOpenRemove}
          sx={{
            textDecoration: 'underline',
            padding: '0px',
            color: '#EB5757',
            borderRadius: '0px',
            borderColor: '#EB5757',
            fontSize: '13px',
            minWidth: 'auto',
            '&:hover': {
              borderColor: '#EB5757',
            },
            '&:active': {
              borderColor: '#EB5757',
            },
            '&:focus': {
              boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
          }}
          variant="text"
          color="error"
        >
          Delete
        </Button>
        <Dialog
          sx={[{
            '.MuiDialog-paper': {
              position: 'relative',
              px: '55px',
              paddingBottom: '35px',
              pt: '20px',
            }
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              '.MuiDialog-paper': {
                px: '15px',
              }
            }
          }),
          ]}
          open={openRemove}
          onClose={handleCloseRemove}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseModal close={handleCloseRemove} />
          <DialogTitle sx={{
            fontWeight: '700',
            maxWidth: '300px',
            textAlign: 'center'
          }}>
            {`Delete account?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: '400',
              color: 'black'
            }}>
              You will no longer be able to:
              <List sx={{ mt: '5px', pb: 0, fontWeight: '400', listStyleType: 'none' }}>
                <ListItem sx={{ p: 0, display: 'list-item', textAlign: 'center' }}>1. Login</ListItem>
                <ListItem sx={{ p: 0, display: 'list-item', textAlign: 'center' }}>2. Receive email notifications</ListItem>
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              maxWidth: '325px',
              justifyContent: 'center',
            }}
          >
            <Button
              sx={{
                padding: '7px 20px',
                borderRadius: '10px',
                width: '100%',
              }}
              variant="contained"
              color='error'
              onClick={onSubmitRemove}
              autoFocus
            >
              Delete account
            </Button>
          </DialogActions>
          <Box sx={{
            maxWidth: '325px',
            justifyContent: 'center',
            textAlign: 'center',
            color: theme.palette.error.main
          }}>
            {responseError}
          </Box>
        </Dialog>
      </Box>
    </Box>
  </div>
};

export default VendorGeneralSettings;

