import { useState, useEffect } from 'react';
import {
  Box,
  Switch,
  Typography,
  FormGroup,
  FormControlLabel,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  Tooltip,
} from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from '../hooks/useAuth';
import { removeUser, switchRoleMode, toggleUserRole } from '../service/userService';
import { useResponseError } from '../hooks/useResponseError';
import { useTheme } from '@mui/material/styles';
import { getUserData } from '../service/userService';
import { useLayout } from '../pages/Layout';
import { CloseModal } from './modal/CloseModal';
import ReactGA from "react-ga4";

const BuyerGeneralSettings = ({ clearSignature }: { clearSignature: Function }) => {
  const { toggleShowSwitch } = useLayout();
  const { logout, setUser, user, isVendor, isBuyer, userType } = useAuth();
  const theme = useTheme();
  const { responseError, setResponseError } = useResponseError();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);//userType === 'VENDOR' ? true : false);
  const [accountStatus, setAccountStatus] = useState('Press the switch to enter vendor mode');
  const [toggleModalStatus, setToggleModalStatus] = useState('');
  const [open, setOpen] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/buyer')) {
      setChecked(false);
    }

    if (location.pathname.includes('/vendor')) {
      setChecked(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    setChecked(userType === 'VENDOR' ? true : false);
  }, [user]);

  useEffect(() => {
    const getUserObj = async () => {
      try {
        const res = await getUserData({});

        setUser(res);
      } catch (error: any) {
        console.error('error', error)
      }
    };

    getUserObj();

    return () => { };
  }, []);

  const handleClickToggleRole = async (isRemove: boolean) => {
    try {
      const res = await toggleUserRole({
        id: user.id,
        role: isRemove ? "BUYER" : "VENDOR"
      })

      if(isRemove) {
        ReactGA.event('removeRole', 
        {
          userID: user?.id,
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phoneNumber: user?.phoneNumber,
          city: user?.city,
          state: user?.state,
          zip: user?.zip,
          roles: user?.roles,
        });
      } else {
        ReactGA.event('addRole', 
        {
          userID: user?.id,
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phoneNumber: user?.phoneNumber,
          city: user?.city,
          state: user?.state,
          zip: user?.zip,
          roles: user?.roles,
        });
      }

      setOpen(true);
      const isVendor = res?.roles.includes('VENDOR');
      const isBuyer = res?.roles.includes('BUYER');
      toggleShowSwitch(isVendor && isBuyer ? true : false);
      setToggleModalStatus(isRemove ? 'Buyer role was successfully deleted' : 'Vendor Account was successfully added');
      setUser(res);
    } catch (error: any) {
      setResponseError(error.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (toggleModalStatus === 'Buyer role was successfully deleted') {
      navigate('/vendor/settings')
    }
  };

  const handleClickOpenRemove = () => {
    setOpenRemove(true);
  };

  const handleCloseRemove = () => {
    setOpenRemove(false);
  };

  const onSubmitRemove = async () => {
    try {
      const res = await removeUser({
        id: user.id,
      })

      ReactGA.event('removeUser', 
      {
        userID: user?.id,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.phoneNumber,
        city: user?.city,
        state: user?.state,
        zip: user?.zip,
        roles: user?.roles,
      });

      setOpenRemove(false);
      logout();
    } catch (error: any) {
      setResponseError(error.message);
    }
  }

  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setAccountStatus(event.target.checked ? 'Press the switch to enter buyer mode' : 'Press the switch to enter vendor mode');

    const res = await switchRoleMode(event.target.checked ? 'VENDOR' : 'BUYER', user?.id);
    clearSignature(userType);
    setUser(res);

    if (event.target.checked) {
      navigate("/vendor/settings");
    } else {
      navigate("/buyer/settings");
    }
  };

  const getAccountStatus = () => {
    if (isVendor && isBuyer) {
      return 'Press the switch to enter vendor mode'
    }

    if (isVendor && !isBuyer) {
      return 'Vendor mode active'
    }

    if (!isVendor && isBuyer) {
      return 'Buyer mode active'
    }

    return 'Buyer mode active'
  }

  const renderAccountControls = () => {

    if (isVendor) {
      return (
        <>
          <Button
            sx={{
              fontSize: '13px',
              px: '20px',
              marginBottom: '12px',
              color: '#EB5757',
              borderRadius: '10px',
              borderColor: '#EB5757',
              '&:hover': {
                borderColor: '#EB5757',
              },
              '&:active': {
                borderColor: '#EB5757',
              },
              '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
              },
            }}
            variant="outlined"
            color="error"
            onClick={() => handleClickToggleRole(true)}
          >
            Delete this role
          </Button>
          <Typography variant="body1" sx={{
            fontWeight: '400',
            fontSize: '13px',
            color: '#666666',
          }}>
            You will be able to restore it at any time.
          </Typography>
        </>
      )
    } else {
      return (
        <>
          <Button
            sx={{
              fontSize: '13px',
              px: '20px',
              marginBottom: '12px',
              borderRadius: '10px',
            }}
            variant="contained"
            onClick={() => handleClickToggleRole(false)}
          >
            Add Vendor account
          </Button>
          <Typography variant="body1" sx={{
            fontWeight: '400',
            fontSize: '13px',
            color: '#666666',
          }}>
            You can add another role and switch between them at any time.
          </Typography>
        </>
      )
    }
  }

  return <div>
    <Box sx={{
      'display': 'flex',
      'flexDirection': 'column',
    }}>
      <Typography variant="h4" sx={{
        fontWeight: '400',
        fontSize: '21px',
        color: '#000',
        marginBottom: '10px',
      }}>
        Active account
      </Typography>
      <Typography variant="body1" sx={{
        fontWeight: '400',
        fontSize: '13px',
        color: '#000',
        marginBottom: '18px',
      }}>
        {getAccountStatus()}
      </Typography>
      {isVendor && isBuyer &&
        <Box sx={{
          marginBottom: '30px',
        }}>
          <FormGroup>
            <FormControlLabel
              sx={{
                color: '#666666',
                marginLeft: '-8px',
              }}
              control={
                <Tooltip title="Switch mode">
                  <Switch
                    checked={checked}
                    onChange={handleSwitchChange}
                  />
                </Tooltip>
              }
              label={checked ? "Vendor mode active" : "Buyer mode active"}
            />
          </FormGroup>
        </Box>
      }
      <Box sx={{
        marginBottom: '60px'
      }}>
        {renderAccountControls()}
        <Dialog
          sx={[{
            '.MuiDialog-paper': {
              px: '55px',
              paddingBottom: '35px',
              pt: '20px',
            }
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              '.MuiDialog-paper': {
                px: '15px',
              }
            }
          }),
          ]}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseModal close={handleClose} />
          <DialogTitle sx={{
            fontWeight: '700',
            maxWidth: '300px',
            textAlign: 'center'
          }}>
            {toggleModalStatus}
          </DialogTitle>
          <DialogActions
            sx={{
              maxWidth: '325px',
              justifyContent: 'center',
            }}
          >
            <Button
              sx={{
                padding: '7px 20px',
                borderRadius: '10px',
                width: '100%',
              }}
              variant="contained"
              onClick={handleClose}
              autoFocus
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box sx={{
        marginBottom: '60px'
      }}>
        <Typography variant="h5" sx={{
          fontWeight: '400',
          fontSize: '16px',
          color: '#000',
          marginBottom: '10px',
        }}>
          Logout?
        </Typography>
        <Button
          onClick={() => logout()}
          sx={{
            textDecoration: 'underline',
            padding: '0px',
            color: '#666666',
            borderRadius: '0px',
            borderColor: '#EB5757',
            fontSize: '13px',
            minWidth: 'auto',
            '&:hover': {
              borderColor: '#EB5757',
            },
            '&:active': {
              borderColor: '#EB5757',
            },
            '&:focus': {
              boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
          }}
          variant="text"
        >
          Logout
        </Button>
      </Box>
      <Box sx={{
        marginBottom: '60px'
      }}>
        <Typography variant="h5" sx={{
          fontWeight: '400',
          fontSize: '16px',
          color: '#000',
          marginBottom: '10px',
        }}>
          Delete account?
        </Typography>
        <Button
          onClick={handleClickOpenRemove}
          sx={{
            textDecoration: 'underline',
            padding: '0px',
            color: '#EB5757',
            borderRadius: '0px',
            borderColor: '#EB5757',
            fontSize: '13px',
            minWidth: 'auto',
            '&:hover': {
              borderColor: '#EB5757',
            },
            '&:active': {
              borderColor: '#EB5757',
            },
            '&:focus': {
              boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
          }}
          variant="text"
          color="error"
        >
          Delete
        </Button>
        <Dialog
          sx={[{
            '.MuiDialog-paper': {
              px: '55px',
              paddingBottom: '35px',
              pt: '20px',
              position: 'relative'
            }
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              '.MuiDialog-paper': {
                px: '15px',
              }
            }
          }),
          ]}
          open={openRemove}
          onClose={handleCloseRemove}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseModal close={handleCloseRemove} />
          <DialogTitle sx={{
            fontWeight: '700',
            maxWidth: '300px',
            textAlign: 'center'
          }}>
            {`Delete account?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: '400',
              color: 'black'
            }}>
              You will no longer be able to:
              <List sx={{ mt: '5px', pb: 0, fontWeight: '400', listStyleType: 'none' }}>
                <ListItem sx={{ p: 0, display: 'list-item', textAlign: 'center' }}>1. Login</ListItem>
                <ListItem sx={{ p: 0, display: 'list-item', textAlign: 'center' }}>2. Receive email notifications</ListItem>
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              maxWidth: '325px',
              justifyContent: 'center',
            }}
          >
            <Button
              sx={{
                padding: '7px 20px',
                borderRadius: '10px',
                width: '100%',
              }}
              variant="contained"
              color='error'
              onClick={onSubmitRemove}
              autoFocus
            >
              Delete account
            </Button>
          </DialogActions>
          <Box sx={{
            maxWidth: '325px',
            justifyContent: 'center',
            textAlign: 'center',
            color: theme.palette.error.main
          }}>
            {responseError}
          </Box>
        </Dialog>
      </Box>
    </Box>
  </div>
};

export default BuyerGeneralSettings;

